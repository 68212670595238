import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
                 <div class="footer">
                    <div class="logoF">Sample Name</div>
                    <div class="copyright">Copyright © 2023 Sample Name. All Rights Reserved</div>                   
                </div>

            </div>
        )
    }
}

export default Footer;

