import React, { Component } from 'react';
import '../App.css';
import footPrint2 from '../assets/rm.png';
import $ from "jquery";
import wand from '../assets/magic-wand.png';

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">

                <div class="storyH">ROADMAP</div>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>PHASE - I</h2>                           
                            <p><img class="wand" src={wand} />Develop an initial concept for the NFT project, including the target audience, themes, and potential use cases.</p>
                            <p><img class="wand" src={wand} />Conduct market research to determine current trends and identify potential competitors in the NFT space.</p>
                            <p><img class="wand" src={wand} />Build a team with the necessary skills to execute on the project, including developers, designers, and marketers.</p>
                            <p><img class="wand" src={wand} />Begin development of the NFT platform and smart contract infrastructure, ensuring the code is secure and optimized for performance.</p>
                            <p><img class="wand" src={wand} />Conduct thorough testing to identify and resolve any issues before moving on to the next phase.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>PHASE - II</h2>
                             <p><img class="wand" src={wand} />Launch the NFT platform and begin marketing efforts to drive awareness and adoption.</p>
                            <p><img class="wand" src={wand} />Collaborate with artists and creators to produce unique NFTs that resonate with the target audience.</p>
                            <p><img class="wand" src={wand} />Foster a community around the NFT project, engaging with users on social media and other platforms.</p>
                            <p><img class="wand" src={wand} />Implement features to improve the user experience, such as secondary market trading and social features.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>PHASE - III</h2>
                             <p><img class="wand" src={wand} />Expand the reach of the NFT platform by partnering with other companies and organizations in the crypto and art communities.</p>
                            <p><img class="wand" src={wand} />Introduce new features and functionalities to the platform, such as gamification elements or cross-chain interoperability.</p>
                            <p><img class="wand" src={wand} />Explore new use cases for NFTs, such as virtual real estate or ticketing.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                    </ul>
                </div>

            </div>


        );

    }
}

export default Roadmap;