import React, { Component } from 'react';
 
class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

        <div class="storyH">FAQ</div>

          <details >
            <summary>Put Your Question Here</summary>
            <div class="faq__content">
              <p>Put Your Answer Here</p>
            </div>
          </details>
          <details>
            <summary>Put Your Question Here</summary>
            <div class="faq__content">
            <p>Put Your Answer Here</p>
            </div>
          </details>
          <details>
            <summary>Put Your Question Here</summary>
            <div class="faq__content">
            <p>Put Your Answer Here</p>
            </div>
          </details>

          <details>
            <summary>Put Your Question Here</summary>
            <div class="faq__content">
            <p>Put Your Answer Here</p>
            </div>
          </details>

          <details>
            <summary>Put Your Question Here</summary>
            <div class="faq__content">
            <p>Put Your Answer Here</p>
            </div>
          </details>         

        </div>
      </div>


    )
  }
}

export default FAQ;

